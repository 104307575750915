@import "~@boomrank/react-components/src";

.beta {
  .dashboard .headline {
    background-image: None;
    @apply bg-br-red;
  }
}

.dashboard {
  .headline {
    h1 {
      @apply text-white
    }
  }
}

#link-table thead tr th {
  &:first-child {
    @apply lg:w-16
  }
  &:nth-child(2) {
    @apply lg:w-24
  }
  &:nth-child(7) {
    @apply lg:w-36
  }
  &:nth-child(9) {
    @apply lg:w-36
  }
}

#quote-link-table thead tr th {
  &:first-child {
    @apply lg:w-0
  }
}

.container-wrapper {
  width: 100%;

  @media (min-width: 768px) {
    max-width: calc(100dvw - 90px);
  }
}